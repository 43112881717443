<template>
  <div
    :class="
      name == 'headerproduct' || name == 'header'
        ? 'min-w-[26px] h-[26px] xl:min-w-[113px] xl:h-[36px]'
        : ''
    "
  >
    <Dropdown
      v-if="Object.keys(locales).length > 0"
      width="xl"
      :position="position"
      v-bind="$attrs"
      hasOverflowHidden
      dropdown-type="locales"
      :name="name"
      :align="align"
    >
      <template #button-content="{ onClickOpen, isOpen }">
        <button
          id="language"
          name="language"
          role="button"
          :aria-labelledby="`locale-selector${name}`"
          class="flex tracking-tighter border border-solid items-center text-[22px] leading-[29.7px] xl:px-3 xl:py-1 font-bold focus:outline-none focus:ring-white hover:opacity-60"
          :class="[
            name === 'footer'
              ? 'text-[#D4D4D4] rounded-[50px] border-[#D4D4D4] px-4 py-1 hover:opacity-90'
              : name === 'headerproduct'
              ? 'text-[#5a5a5a] border-transparent'
              : 'text-[#D4D4D4] border-transparent',
            (scrollPosition >= 80 || isStorePage) && name != 'footer'
              ? 'text-[#D4D4D4] border-transparent xl:border-[#D4D4D4] xl:rounded-full text-[20px]'
              : name == 'footer' && 'text-[#D4D4D4]   ',
          ]"
          @click.prevent="onClickOpen"
        >
          <GlobeIcon
            class="hidden lg:block w-[20px] h-[20px]"
            :class="
              mainStore.enableShop
                ? 'me-5 md:me-3'
                : name !== 'headermobile'
                ? 'me-5 md:me-3'
                : ''
            "
            :color="
              isProductPage && name === 'headerproduct' ? '#5a5a5a' : '#D4D4D4'
            "
          />
          <GlobeIcon
            class="block lg:hidden w-[20px] h-[20px]"
            :class="
              mainStore.enableShop
                ? 'me-5 md:me-3'
                : name !== 'headermobile'
                ? 'me-5 md:me-3'
                : ''
            "
            :color="
              isProductPage && name === 'headerproduct' ? '#5a5a5a' : '#D4D4D4'
            "
          />
          <span
            class="xl:block after:content-['-'] after:mx-1 font-semibold text-base"
            :class="{ hidden: name !== 'footer' }"
          >
            {{ mainStore.country.toUpperCase() }}
          </span>
          <span
            class="text-base font-semibold xl:block"
            :class="{ hidden: name !== 'footer' }"
          >
            {{ mainStore.locale.toUpperCase() }}
          </span>
          <AngleDown
            class="xl:block w-[18px] h-[18px] ms-3"
            :class="[
              name !== 'footer' ? 'hidden' : '',
              isOpen ? 'rotate-180' : '',
            ]"
          />
        </button>
      </template>

      <!-- Locales selector -->
      <div
        :id="`locale-selector${name}`"
        class="h-[28rem] max-h-[28rem] px-6 py-6"
      >
        <div>
          <h3 class="mb-4 text-2xl font-semibold text-gray-700">
            {{ $t("common.locale_modal_choose_your_country") }}
          </h3>
          <input
            :id="name"
            class="absolute z-[-1] opacity-0"
            type="checkbox"
            :name="name"
            @click="collapseHeight($event)"
          />
          <label
            class="relative block text-xl text-gray-700 cursor-pointer"
            :for="name"
          >
            {{ $t("common.locale_modal_list_all_countries") }}
            <AngleDown
              class="inline mb-[0.2rem] ml-[0.1rem] transition-transform w-[18px] h-[18px]"
              :class="isCollapse ? 'rotate-180 ' : ''"
            />
          </label>
        </div>
        <div class="pb-3 overflow-y-auto md:pb-6 overscroll-contain">
          <!-- Collapse -->
          <div
            :id="name + '-collapse'"
            class="mb-10 overflow-hidden tab-content max-h-0"
          >
            <!-- Toggle list -->

            <div
              v-for="(companies, continentName, innerIndex) in locales"
              :key="innerIndex"
              :value="innerIndex"
              class="my-12 first:mt-8"
            >
              <h4
                class="pb-1 mb-4 text-xl font-bold text-gray-700 border-b border-gray-200"
              >
                {{ continentName }}
              </h4>
              <div
                v-for="(company, companyIndex) in companies"
                :key="companyIndex"
                class="my-6 space-y-4"
              >
                <div
                  v-if="
                    !company.country.name
                      .toLowerCase()
                      .includes('international')
                  "
                  class="flex items-end gap-2 text-base text-gray-500 space-s-1"
                >
                  <country-flag :country="company.country.iso" rounded />
                  <span class="relative top-[.2rem]">
                    {{
                      company.country.name === "Hong Kong"
                        ? `${company.country.name}, China`
                        : company.country.name === "Taiwan"
                        ? `${company.country.name}, (ROC)`
                        : company.country.name
                    }}
                  </span>
                </div>

                <div class="flex items-center mt-2 space-s-2">
                  <a
                    v-for="(url, urlIndex) in company.urls"
                    :key="urlIndex"
                    :href="url.url + urlPath"
                    class="text-xs uppercase py-0.5 px-2 inline-flex items-center border rounded-md border-gray-300 text-black hover:no-underline hover:text-primary-700 hover:border-primary-700 hover:bg-primary-100 transition-all ease"
                  >
                    <span>{{ url.locale_name }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Select -->
        <div
          :id="name + '-select'"
          class="absolute inset-x-0 w-full px-6 text-gray-700 bottom-6"
        >
          <div class="h-full">
            <!-- Continents -->
            <select
              v-model="continentsKey"
              name="continents"
              class="block w-full mb-6 border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
              @change="resetValues"
            >
              <option
                v-for="(companies, continentName, innerIndex) in getContinents"
                :key="innerIndex"
                :value="innerIndex"
              >
                {{ continentName }}
              </option>
            </select>

            <!-- Countries -->
            <select
              v-model="countriesKey"
              name="countries"
              class="block w-full mb-6 border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
              :disabled="isCountryDisable"
              @change="selectLocale($event)"
            >
              <option
                v-for="(country, index) in getCountries.filter(
                  (_country) => !_country.settings.under_maintenance
                )"
                :key="index"
                :value="index"
              >
                {{
                  country.name === "Hong Kong"
                    ? `${country.name}, China`
                    : country.name === "Taiwan"
                    ? `${country.name}, (ROC)`
                    : country.name === "Global" ||
                      country.name === "International"
                    ? "-"
                    : country.name
                }}
              </option>
            </select>

            <!-- Locales -->
            <select
              v-for="(locales, index) in getLocales.filter(
                (_country) => !_country.settings.under_maintenance
              )"
              :id="`locale-${name}`"
              :key="index"
              v-model="localesKey"
              name="locales"
              class="block w-full mb-8 border-gray-300 rounded-md locale-selected focus:ring-primary-500 focus:border-primary-500"
              :class="{
                hidden: countriesKey !== index,
                grid: countriesKey === index,
                selected: countriesKey === index,
              }"
            >
              <option
                v-for="(url, localeIndex) in locales.urls"
                :key="localeIndex"
                :value="localeIndex"
                :data-url="url.url + urlPath"
                :selected="
                  locales.urls.findIndex(
                    (y) => y.locale_code === locale || y.default
                  )
                "
              >
                {{ capitalize(url.locale_name) }}
              </option>
            </select>

            <!-- Button -->
            <Btn
              type="submit"
              size="xl"
              block
              color="primary"
              @click="changeLocale(name)"
              v-text="$t('common.submit')"
            />
          </div>
        </div>
      </div>
    </Dropdown>
  </div>
</template>

<script setup>
import { sortBy } from "lodash-es";
import { useLocaleStore } from "@/store/locales";
import { useMainStore } from "@/store/index";
import CountryFlag from "vue-country-flag-next";
import GlobeIcon from "@/components/GlobeIcon";
import AngleDown from "@/assets/svg/heroicons/arrow-down.svg?component";

const props = defineProps({
  name: {
    type: String,
    default: "header",
  },
  color: {
    type: String,
    required: false,
    default: "white",
  },
  position: {
    type: String,
    default: "top",
  },
});

const router = useRouter();
const localeStore = useLocaleStore();
const mainStore = useMainStore();
const nuxtApp = useNuxtApp();

const { $cookies } = nuxtApp;
const isProductPage = ref(false);
const isCollapse = ref(false);
const urlPath = ref("");
const continentsKey = ref(0);
const countriesKey = ref(0);
const localesKey = ref(0);
const scrollPosition = ref(0);
const defaultLoad = ref(true);
const localeLoading = ref(true);
const isStorePage = ref(false);

watch(
  router.currentRoute,
  (newValue, oldValue) => {
    if (newValue.name == "country-products-slug") {
      isProductPage.value = true;
    } else {
      isProductPage.value = false;
    }
    if (newValue.name == "country-stores") {
      isStorePage.value = true;
    } else {
      isStorePage.value = false;
    }
  },
  { deep: true }
);

const country = mainStore.country;
const locale = mainStore.locale;
const hasClickoutSide = ref(localeStore.hasClickoutSide);

watch(hasClickoutSide, (newValue, oldValue) => {
  if (!newValue) {
    setTimeout(() => {
      resetValues();
      defaultLoad.value = true;
      localeStore.handleLocaleUpdating();
    }, 100);
  }
});

const locales = computed(() => {
  return localeStore.locales;
});

const getContinents = computed(() => {
  const continents = sortBy(locales.value, "id");
  for (let i = 0; i < continents.length; i++) {
    // set continent
    continents[i].findIndex((x) => {
      if (x.slug === country) {
        continentsKey.value = i;
      }
    });
  }

  return locales.value;
});
const getCountries = computed(() => {
  let countries = locales.value;
  countries = sortBy(countries, "id");

  if (defaultLoad.value) {
    for (let i = 0; i < countries.length; i++) {
      // set country
      let j = 0;
      countries[i].findIndex((x) => {
        if (x.slug === country) {
          countriesKey.value = j;
        }
        j++;
      });
    }
  }

  return countries[continentsKey.value];
});

const getLocales = computed(() => {
  let localesData = locales.value;

  localesData = sortBy(localesData, "id");
  if (defaultLoad.value) {
    // set locale
    for (let i = 0; i < localesData.length; i++) {
      localesData[i].findIndex((x) => {
        if (x.slug === country) {
          localesKey.value = x.urls.findIndex((y) => y.locale_code === locale);
        }
      });
    }
  }

  return localesData[continentsKey.value];
});

const isCountryDisable = computed(() => {
  return !!getCountries.value.find(
    (_country) =>
      _country.name.includes("Global") ||
      _country.name.includes("International")
  );
});
onMounted(() => {
  isProductPage.value = window.location.href.includes("/products/");
  isStorePage.value = window.location.href.includes("/stores");
  window.addEventListener("scroll", updateScroll);
  localeLoading.value = false;
});

onUnmounted(() => {
  window.removeEventListener("scroll", updateScroll);
});

function collapseHeight(event) {
  isCollapse.value = !isCollapse.value;
  const tabContent = document.querySelector(
    `#${event.target.id}-collapse.tab-content`
  );
  const localeSelect = document.querySelector(`#${event.target.id}-select`);

  if (tabContent.classList.contains("max-h-0")) {
    tabContent.classList.remove("max-h-0", "mb-12");
    localeSelect.classList.add("hidden");
  } else {
    tabContent.classList.add("max-h-0", "mb-12");
    localeSelect.classList.add("block");
    localeSelect.classList.remove("hidden");
  }
}
function resetValues() {
  defaultLoad.value = false;
  countriesKey.value = 0;
  localesKey.value = 0;
}
function selectLocale(event) {
  localesKey.value = getLocales.value[event.target.value].urls.findIndex(
    (_url) => _url.default
  );
  // localesKey.value = 0
}
function changeLocale(name) {
  const selectElement = document.querySelector(
    `#locale-${name}.locale-selected.selected`
  );
  const selectedOptionElement = selectElement
    .querySelector(`option[value="${selectElement.value}"]`)
    .getAttribute("data-url");

  $cookies.set(
    "CountryLocale",
    `${selectedOptionElement.split("/").pop().split("-")[0]}/${
      selectedOptionElement.split("/").pop().split("-")[1]
    }`
  );
  window.location.href = selectedOptionElement;
}
function updateScroll() {
  scrollPosition.value = window.scrollY;
}

function capitalize(data) {
  const capitalized = [];

  data.split(" ").forEach((word) => {
    capitalized.push(
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
  });

  return capitalized.join(" ");
}
</script>
