<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 13C25 16.1826 23.7357 19.2348 21.4853 21.4853C19.2348 23.7357 16.1826 25 13 25M25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1M25 13H1M13 25C9.8174 25 6.76516 23.7357 4.51472 21.4853C2.26428 19.2348 1 16.1826 1 13M13 25C15.2093 25 17 19.6267 17 13C17 6.37333 15.2093 1 13 1M13 25C10.7907 25 9 19.6267 9 13C9 6.37333 10.7907 1 13 1M13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13"
      :stroke="props.color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  color: {
    type: String,
    required: false,
    default: "white",
  },
});
</script>
